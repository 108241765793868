import React from 'react';
import classNames from 'classnames';
import { GLGlobal, GLUtil } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { WijmoGrid, ColumnLink } from '@app/components';
import { SchoolLocale } from '@app/locales/localeid';
import { ClassesModel, ClassesPropsModel } from '@app/service/class';
import { GSSchoolAction, fmtMsg } from '@app/util';

const { Grid, Column } = WijmoGrid;

interface SchoolClassTableProps {
    schoolClasses?: ClassesModel[],
    rowKey?: string
}

export const SchoolClassTable: React.FC<SchoolClassTableProps> = ({schoolClasses, rowKey}) => {

    const getColumns = () => {
        return [
            {
                title: fmtMsg({id:SchoolLocale.ClassesColumnSchool}),
                dataIndex: ClassesPropsModel.schoolName,
                width: "4*",
                render: (text, schoolClass, index) => {
                    const {regionId, schoolId} = schoolClass;
                    const pathCampues = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Schools, { regionId, schoolId }) : null;
                    return (GLGlobal.isActionValid(GSSchoolAction.Campuses) && pathCampues) ?
                        <ColumnLink className={'navigation-link'} url={pathCampues}>
                            {text}
                        </ColumnLink>
                        : text;
                },
            },
            {
                title: fmtMsg({id:SchoolLocale.ClassCampusSelect}),
                dataIndex: ClassesPropsModel.campusName,
                width: "3*",
                render: (text, schoolClass, index) => {
                    const {regionId, schoolId, campusId} = schoolClass;
                    const pathClasses = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId }) : null;
                    return (GLGlobal.isActionValid(GSSchoolAction.Classes) && pathClasses) ?
                        <ColumnLink className={'navigation-link'} url={pathClasses}>
                            {text}
                        </ColumnLink>
                        : text;
                }
            },
            {
                title: fmtMsg({id:SchoolLocale.ClassesColumnClass}),
                dataIndex: ClassesPropsModel.schoolClassName,
                width: "3*",
                render: (text, schoolClass, index) => {
                    const {regionId, schoolId, campusId, schoolClassId: classId} = schoolClass;
                    const pathStudents = regionId && schoolId && campusId && classId ? GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId }) : null;
                    return (GLGlobal.isActionValid(GSSchoolAction.StudentList) && pathStudents) ?
                        <ColumnLink className={'navigation-link'} url={pathStudents}>
                            {text}
                        </ColumnLink>
                        : text;
                }
            }
        ];
    }

    const schoolClassTableClassName = {
        'school-teacher-class': true,
        'have-data': schoolClasses && schoolClasses.length > 0,
        'no-data': !schoolClasses || schoolClasses.length == 0
    }

    return (
        <Grid
            className={classNames(schoolClassTableClassName)}
            itemsSource={schoolClasses}
            pagination={false}
        >
            {
                getColumns().map(column => {
                    return <Column
                        key={column.dataIndex}
                        header={column.title}
                        binding={column.dataIndex}
                        width={column.width}
                        render={column.render}
                    />
                })
            }
        </Grid>
    )
}
