import React, { useState } from 'react';
import { DatePicker } from 'antd-min';
import { GLForm, GLGlobal, FormHelper, LanguageDateFormat, alignPop } from 'gl-commonui';
import { SchoolLocale } from '@app/locales/localeid';
import { DateHelper, fmtMsg, GSSchoolAction, GSAdminAction } from '@app/util';
import { IconButton } from '../../component/icon-button';

const TrainingInfoComponent = ({ form = null, foundationTraining, refresherTraining, onSave, onCancel }) => {

    const foundationTrainingField = 'foundationTraining';
    const refresherTrainingField = 'refresherTraining';
    const extendForm = { ...form, colon: true };
    const [trainingInfoChanged, setTrainingInfoChanged] = useState(false);

    const onTrainingInfoChanged = (e) => {
        setTrainingInfoChanged(true);
    }

    const onCancelTrainingInfo = (e) => {
        saveTrainingInfo(e, false);
    }

    const onSaveTrainingInfo = (e) => {
        saveTrainingInfo(e);
    }

    const saveTrainingInfo = (e, save = true) => {
        e.preventDefault();
        setTrainingInfoChanged(false);
        if (save) {
            onSave({
                [foundationTrainingField]: form.getFieldValue(foundationTrainingField),
                [refresherTrainingField]: form.getFieldValue(refresherTrainingField)
            });
        }
        else {
            form.resetFields();
            onCancel();
        }
    }
    return (
        <GLForm layout='horizontal' className='school-teacher-header-training-info' form={form} onSubmit={onSaveTrainingInfo}>
            {FormHelper.renderFormItem(extendForm, SchoolLocale.SchoolTeacherFoundationTrainingTitle, foundationTrainingField,
            <DatePicker {...alignPop({ type: 'DatePicker' })}
                format={LanguageDateFormat[GLGlobal.intl.locale]}
                disabled={!GLGlobal.isActionValid(GSAdminAction.EditTrainingPath)}
                onChange={onTrainingInfoChanged}
            />, foundationTraining)}
            {FormHelper.renderFormItem(extendForm, SchoolLocale.SchoolTeacherReferesherTrainingTitle, refresherTrainingField,
            <DatePicker {...alignPop({ type: 'DatePicker' })}
                format={LanguageDateFormat[GLGlobal.intl.locale]}
                disabled={!GLGlobal.isActionValid(GSAdminAction.EditTrainingPath)}
                onChange={onTrainingInfoChanged}
            />, refresherTraining)}
            {trainingInfoChanged && 
            <IconButton
                onCancel = {onCancelTrainingInfo}
                onSave = {onSaveTrainingInfo}
            />}
        </GLForm>
    )
}

export const TrainingInfo = GLForm.create()(TrainingInfoComponent);