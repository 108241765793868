import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Row, Button } from 'antd-min';
import { SchoolTeacherReviewHistory } from '@app/service/coach/visitation';
import { CustomDrawer } from '../../component/custom-drawer';
import { SurveyQuestion } from '../../component/survey-question';
import { DateHelper, fmtMsg } from '@app/util';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { SurveyQuestions } from '../../ongoing/component/survey-questions';

interface ReviewDetailProps {
    visible: boolean
    reviewHistory: SchoolTeacherReviewHistory
    form: WrappedFormUtils
    todoSurveyResponse: any
    onClose: () => void
}

export const ReviewDetail: React.FC<ReviewDetailProps> = ({visible, reviewHistory, form, todoSurveyResponse, onClose}) => {
    const { surveyQuestion } = todoSurveyResponse;
    const reviewDate = reviewHistory.reviewDate ? DateHelper.formatDate2Local(reviewHistory.reviewDate) : '';
    return (
        <CustomDrawer 
            width={670}
            visible = {visible} 
            className = 'school-teacher-review-history-drawer'
            drawerTitle={<>
                <Row><h3>{fmtMsg({id: SchoolLocale.VisitationCoachName})}: {reviewHistory && reviewHistory.coachName}</h3></Row>
                <Row><h5>{fmtMsg({id: SchoolLocale.VisitationDateOfVisit})}: {reviewDate}</h5></Row>
            </>}
            onClose = {() => onClose()}
        >
            <div className="send-feedback">
                {surveyQuestion && <SurveyQuestions 
                    form={form}
                    isDisabled={true}
                    forceRender={!visible}
                    isTeacherReview={true}
                    surveyQuestions={surveyQuestion}
                />}
            </div>
            <div className='school-teacher-review-history-drawer-close-button'>
                <Button onClick={() => onClose()} >
                    {fmtMsg({ id: GSAdminLocale.ButtonClose })}
                </Button>
            </div>            
        </CustomDrawer>
    )

}