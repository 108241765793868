import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter, maskThrottle, unmaskThrottle, GLFormComponentProps, GLForm, RoleName, Role, GLGlobal, GLUtil } from 'gl-commonui';
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { SchoolLocale } from '@app/locales/localeid';
import { setBreadcrumbs } from '@app/states/resource';
import { IResourceService } from '@app/service/resources';
import { IVisitationService, SchoolTeacherVisitation, SchoolTeacherTrainingInfo, SchoolTeacherReviewHistoryPropsModel, SchoolTeacherReviewHistory } from '@app/service/coach/visitation';
import { SurveyTodoType, VisitationType } from '@app/util/coach/enum';
import { ITrainingService } from '@app/service/training';
import { ClassesPropsModel } from '@app/service/class';
import {
    stringCompare,
    GSAdminAction,
    ContextHelper,
    TrainingCourseStatus,
    DateHelper,
    sliceMoment,
    GSSchoolAction,
    fmtMsg
} from '@app/util';
import { formatExtraSchoolClass } from "@app/states/dashboard";
import { LabelText } from '@app/page/school/visitation/component/label-text';
import { UserAvatar } from '../component/avatar';
import { TrainingInfo } from './component/training-info';
import { ReviewInfo } from './component/review-info';
import { TeacherNotes } from './component/note';
import { SchoolClassTable } from './component/school-class';
import { ReviewHistoryTable } from './component/review-history';
import { ReviewDetail } from './component/review-detail';
import { CourseHistory } from './component/course-history';
import { IUserService } from '@app/service/users';
import './style.less';
import { Tagging } from '@app/components/tagging/tagging';
import { TagEntity } from '@app/service/tag-manager';
import { IReportService } from '@app/service/school/report';

interface SchoolTeacherProps extends RouteComponentProps<any>, GLFormComponentProps {
    setBreadcrumbs?: (data) => void
}

export const SchoolTeacher = (props: SchoolTeacherProps) => {

    const [courseHistoryLoading, setCourseHistoryLoading] = useState(false);
    const regionId = props.match.params.regionId;
    const schoolId = props.match.params.schoolId;
    const paramsTeacherId = props.match.params.teacherId;
    const teacherId = paramsTeacherId ? paramsTeacherId : ContextHelper.getUserLoginInfo().profile.sub;
    const userService = useService<IUserService>(TYPES.IUserService);
    const resourceService = useService<IResourceService>(TYPES.IResourceService);
    const trainingService = useService<ITrainingService>(TYPES.ITrainingService);
    const reportService = useService<IReportService>(TYPES.IReportService);
    const visitationService = useService<IVisitationService>(TYPES.IVisitationService);
    const [teacherVisitation, setTeacherVisitation] = useState({} as SchoolTeacherVisitation);
    const [teacherTrainingInfo, setTeacherTrainingInfo] = useState({} as SchoolTeacherTrainingInfo);
    const [courseHistories, setCourseHistories] = useState([]);
    const [courseHistoryVisible, setCourseHistoryVisible] = useState(false);
    const [schoolClasses, setSchoolClasses] = useState([]);
    const [schoolClassesRowKey, setSchoolClassesRowKey] = useState('schoolClassId');
    const [teacherNotes, setTeacherNotes] = useState(null);
    const [reviewDetailVisible, setReviewDetailVisible] = useState(false);
    const [currentReviewHistory, setCurrentReviewHistory] = useState({} as SchoolTeacherReviewHistory);
    const [todoSurveyResponse, setTodoSurveyResponse] = useState({});
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        hideOnSinglePage: true,
        pageSize: 20,
        onChange: (page) => onViewCourseHistory(page),
    });

    useEffect(() => {
        maskThrottle();
        let promises = [], schoolClasses = [];
        promises.push(reportService.getReviewedSummary(teacherId));
        promises.push(resourceService.getTeacherNotes(teacherId));
        const routeData = {
            userId: teacherId,
            userRole: Role[RoleName.teacher]
        };
        const queryData = {
            sortBy: [ClassesPropsModel.schoolName, ClassesPropsModel.campusName, ClassesPropsModel.schoolClassName],
            isDescending: [false, false, false]
        };
        promises.push(resourceService.getLandingResources({ routeData, queryData }));
        promises.push(visitationService.getTeacherReviewHistory(teacherId, schoolId));
        promises.push(trainingService.getCourseTrainingInfo(teacherId));
        promises.push(userService.getUserAvatarSasUri({ id: teacherId, expirationminutes: 120 }));
        const getExtralSchoolClass = async () => {
            setSchoolClassesRowKey('index');
            setSchoolClasses(formatExtraSchoolClass(await resourceService.getAccessResources({
                roles: [Role.Teacher],
                includeParent: true
            }, {
                userId: teacherId
            })).filter(c => !schoolId || (schoolId && c.schoolId == schoolId)));
        };
        Promise.all(promises).then((result) => {
            const { id: teacherId, name: teacherName } = result[3].teacherResource;
            schoolClasses = schoolId ? result[2].data.filter(c => c.schoolId == schoolId) : result[2].data;
            formatTrainingInfo(result[4].statuses);
            setTeacherNotes(result[1]);
            if (schoolClasses.length == 0) {
                getExtralSchoolClass();
            }
            else {
                setSchoolClasses(schoolClasses);
            }
            setTeacherVisitation({
                teacherId,
                teacherName,
                teacherAvatar: result[5],
                videoViewed: result[0],
                reviewHistories: formatReviewHistories(result[3].teacherReviewHistoryInfos)
            });
            props.setBreadcrumbs({
                teacher: {
                    id: teacherId,
                    name: teacherName
                }
            });
            unmaskThrottle();
        }).catch(er => {
            unmaskThrottle();
        });

        const queryParams = GLUtil.queryParse();

        if (queryParams && queryParams.questionnaireId && queryParams.courseVersionId) {
            onViewCourseHistory();
        }
    }, []);

    const formatTrainingInfo = (trainingStatus) => {
        const refresherTraining = trainingStatus.find(s => s.courseTypeId == TrainingCourseStatus.RefresherTraining && s.completionDate != null);
        const foundationTraining = trainingStatus.find(s => s.courseTypeId == TrainingCourseStatus.FoundationTraining && s.completionDate != null);
        setTeacherTrainingInfo({
            refresherTraining: refresherTraining ? DateHelper.toLocalMoment(refresherTraining.completionDate) : null,
            foundationTraining: foundationTraining ? DateHelper.toLocalMoment(foundationTraining.completionDate) : null
        });
    }

    const formatReviewHistories = (histories: any[]) => {
        return histories.map((info) => {
            return {
                coachId: info.coachResource.id,
                coachName: info.coachResource.name,
                reviewDate: info.reviewDate,
                type: info.type,
                typeText: info.type === VisitationType.OnSite ? fmtMsg({ id: SchoolLocale.VisitationCoachVisitationText }) : fmtMsg({ id: SchoolLocale.VisitationLVAVisitationText }),
                schoolId: info.schoolResource.id,
                schoolName: info.schoolResource.name,
                visitationId: info.visitationId,
                surveyInstanceId: info.surveyInstanceId,
            }
        }).sort((a, b) => -stringCompare(a[SchoolTeacherReviewHistoryPropsModel.reviewDate], b[SchoolTeacherReviewHistoryPropsModel.reviewDate], 'en'));
    }

    const onSaveTrainingInfo = (trainingInfo: SchoolTeacherTrainingInfo) => {
        maskThrottle();
        const updateData = [
            {
                courseTypeId: TrainingCourseStatus.FoundationTraining,
                completionDate: sliceMoment(trainingInfo.foundationTraining),
                userId: teacherId
            },
            {
                courseTypeId: TrainingCourseStatus.RefresherTraining,
                completionDate: sliceMoment(trainingInfo.refresherTraining),
                userId: teacherId
            }
        ];
        trainingService.saveCourseTrainingInfo(updateData).then((result) => {
            unmaskThrottle();
        }).catch(er => {
            unmaskThrottle();
        });
    }

    const onCancelTrainingInfo = () => {
        maskThrottle();
        trainingService.getCourseTrainingInfo(teacherId).then((result) => {
            formatTrainingInfo(result.statuses);
            unmaskThrottle();
        }).catch(er => {
            unmaskThrottle();
        });
    }

    const onSaveTeacherNotes = (notes) => {
        maskThrottle();
        resourceService.updateTeacherNotes(teacherId, notes).then((result) => {
            setTeacherNotes(notes);
            unmaskThrottle();
        }).catch(er => {
            unmaskThrottle();
        });
    }

    const onCancelTeacherNotes = () => {
        maskThrottle();
        resourceService.getTeacherNotes(teacherId).then((result) => {
            setTeacherNotes(result);
            unmaskThrottle();
        }).catch(er => {
            unmaskThrottle();
        });
    }

    const onViewCourseHistory = (page?: number) => {
        setCourseHistoryLoading(true);
        setCourseHistoryVisible(true);
        const limit = pagination.pageSize;
        let pageNumber = pagination.current;
        if (page) {
            pageNumber = page;
        }
        reportService.getCourseHistory(teacherId, (pageNumber - 1) * limit, limit).then((result) => {
            setCourseHistories(result.data.map((c, index) => {
                return {
                    index,
                    itemId: c.item.id,
                    itemName: c.item.name,
                    courseVersionId: c.courseVersionModel ? c.courseVersionModel.id : null,
                    courseName: c.courseModel.name,
                    seriesId: c.seriesModel ? c.seriesModel.id : null,
                    seriesName: c.seriesModel ? c.seriesModel.name : '',
                    date: c.date,
                    type: c.type
                }
            }).sort((a, b) => -stringCompare(a.date, b.date, 'en')));
            setPagination({
                ...pagination,
                current: result.currentPage,
                total: result.totalRecords,
                pageSize: result.pageSize,
            })
            setCourseHistoryLoading(false);
        }).catch(er => {
            setCourseHistoryLoading(false);
        });
    }

    const onViewHistoryDetail = (reviewHistory: SchoolTeacherReviewHistory) => {
        maskThrottle();
        const params = {
            surveyInstanceId: reviewHistory.surveyInstanceId,
            visitationId: reviewHistory.visitationId,
            type: SurveyTodoType.ReadOnlyShared
        }
        visitationService.viewSurveyReponse(params).then((result) => {
            setCurrentReviewHistory(reviewHistory);
            setReviewDetailVisible(true);
            setTodoSurveyResponse(result);
            unmaskThrottle();
        }).catch(er => {
            unmaskThrottle();
        });
    }

    const onGetCourseQuestionnaire = (teacherId, questionnaireId, courseVersionId, seriesId) => {
        return trainingService.getCourseQuestionnaire(teacherId, questionnaireId, courseVersionId, seriesId);
    }

    const onCourseHistoryClose = () => {
        setCourseHistoryVisible(false);
        setPagination({
            ...pagination,
            current: 1,
            total: 0,
            pageSize: 20,
        });
        const { questionnaireId, courseVersionId } = GLUtil.queryParse();

        if (questionnaireId && courseVersionId) {
            // used window.history.replaceState instead of props.history.push or props.history.replace
            // because the latter causes many different api calls which is not required.
            window.history.replaceState(null, null, window.location.pathname);
        }
    }

    const renderContent = () => {
        const hasTagViewPermission = GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherTagView);
        const hasTagManagerPermission = GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherTagManage);
        return (
            <div className='content-layout school-teacher'>
                <div className='page-content'>
                    <div className='school-teacher-header'>
                        <div className="school-teacher-header__left">
                            <UserAvatar
                                avatarSource={teacherVisitation.teacherAvatar}
                                userName={teacherVisitation.teacherName}
                            />
                            {hasTagViewPermission && (
                                <Tagging
                                    entityId={teacherId}
                                    regionId={regionId}
                                    entity={TagEntity.Teacher}
                                    isViewOnly={!hasTagManagerPermission}
                                />
                            )}
                        </div>
                        <TrainingInfo
                            foundationTraining={teacherTrainingInfo.foundationTraining}
                            refresherTraining={teacherTrainingInfo.refresherTraining}
                            onSave={onSaveTrainingInfo}
                            onCancel={onCancelTrainingInfo}
                        />
                        <ReviewInfo
                            videoViewed={teacherVisitation.videoViewed}
                            onViewCourseHistory={onViewCourseHistory}
                            classPrefix="school-teacher"
                        />
                    </div>
                    <CourseHistory
                        visible={courseHistoryVisible}
                        teacherId={teacherId}
                        histories={courseHistories}
                        pagination={pagination}
                        classPrefix="school-teacher"
                        getQuestionnaire={onGetCourseQuestionnaire}
                        onClose={onCourseHistoryClose}
                        courseHistoryLoading={courseHistoryLoading}
                    />
                    {GLGlobal.isActionValid(GSAdminAction.EditTeacherNotes) &&
                        <TeacherNotes
                            notes={teacherNotes}
                            onSave={onSaveTeacherNotes}
                            onCancel={onCancelTeacherNotes}
                        />}
                    <SchoolClassTable
                        schoolClasses={schoolClasses}
                        rowKey={schoolClassesRowKey}
                    />
                    <div className='school-teacher-visitation'>
                        <div className='school-teacher-visitation-title'>
                            <LabelText
                                labelId={SchoolLocale.SchoolTeacherVisitationHistoryTitle}
                                hasColon={false}
                            />
                        </div>
                        <ReviewHistoryTable
                            routeParams={props.match.params}
                            histories={teacherVisitation.reviewHistories}
                            onViewHistoryDetail={onViewHistoryDetail}
                        />
                    </div>
                    <ReviewDetail
                        visible={reviewDetailVisible}
                        reviewHistory={currentReviewHistory}
                        form={props.form}
                        todoSurveyResponse={todoSurveyResponse}
                        onClose={() => setReviewDetailVisible(false)}
                    />
                </div>
            </div>
        )
    }

    return renderContent();
}

export const SchoolTeacherPage = withRouter(connect(null, { setBreadcrumbs })(GLForm.create()(SchoolTeacher)));
