import React, { useState } from 'react';
import { Form, Input, Tooltip, Icon, Alert } from 'antd-min';
import { GLForm, FormHelper, ComparisonOperator, GLGlobal } from 'gl-commonui';
import { FormattedMessage } from 'react-intl';
import { SchoolLocale } from '@app/locales/localeid';
import { IconButton } from '../../component/icon-button';
import { GSAdminAction } from '@app/util/index';

const Note = ({ notes, onCancel, onSave, form = null }) => {
    const noteFieldName = 'notes';
    const maxLength = 2000;
    const [isEdit, setIsEdit] = useState(false);
    const setEditState = (e, editState) => {
        e.preventDefault();
        setIsEdit(editState);
    }
    return (
        <Form.Item
            className="school-teacher-notes"
            label={
                <span>
                    <FormattedMessage id={SchoolLocale.SchoolTeacherVisitationNoteTitle} />
                    <Tooltip
                        title={<FormattedMessage id={SchoolLocale.SchoolTeacherVisitationNoteTip} />}
                        overlayClassName="school-teacher-notes-popover"
                        placement="topLeft"
                        key="school-teacher-notes"
                    >
                        <Icon type="info-circle" className="contact-info-icon" />
                    </Tooltip >
                    {!isEdit && GLGlobal.isActionValid(GSAdminAction.EditTeacherNotes) &&
                    <a className="school-teacher-notes-edit-button" onClick={(e) => { setEditState(e, true) }}>
                        <Icon type="edit" />
                    </a>}
                    {isEdit &&
                    <IconButton
                        onCancel = {(e) => { setEditState(e, false); onCancel(); }}
                        onSave = {(e) => { setEditState(e, false); onSave(form.getFieldValue(noteFieldName)); }}
                    />}
                </span>
            }
        >
        {isEdit ?
        form.getFieldDecorator(noteFieldName, {
            initialValue: notes,
            rules: [FormHelper.ruleForCompareLength(SchoolLocale.SchoolTeacherVisitationNoteTitle, ComparisonOperator.LessOrEqualsThan, maxLength)],
            validateTrigger: 'onBlur'
        })(
            <Input.TextArea maxLength={maxLength} />)
        :
            <div className="school-notes-value">
                {notes != null && notes.trim() != ''? <Alert message={<pre>{notes.trim()}</pre>} type="success"></Alert> : <pre><span> </span></pre>}
            </div>
        }
        </Form.Item>
    )
}

export const TeacherNotes = GLForm.create()(Note);