import React from 'react';
import { ColumnProps } from "antd/lib/table";
import { Icon } from 'antd-min';
import { GLGlobal, GLUtil } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolLocale } from '@app/locales/localeid';
import { fmtMsg, DateHelper, GSAdminAction, GSSchoolAction } from '@app/util';
import { SchoolTeacherReviewHistory, SchoolTeacherReviewHistoryPropsModel } from '@app/service/coach/visitation';
import { VisitationType } from '@app/util/coach/enum';
import { HistoryTable } from '../../component/history-table';
import { ColumnLink } from "@app/components";

interface ReviewHistoryTableProps {
    routeParams: any
    histories?: SchoolTeacherReviewHistory[]
    onViewHistoryDetail?: (reviewHistory: SchoolTeacherReviewHistory) => void
}

export const ReviewHistoryTable: React.FC<ReviewHistoryTableProps> = ({routeParams, histories, onViewHistoryDetail}) => {

    const getColumns = () : ColumnProps<SchoolTeacherReviewHistory>[] => {
        return [
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherVisitationHistoryCoachColumn}),
                dataIndex: SchoolTeacherReviewHistoryPropsModel.coachName,
                width: '30*',
                render: (text, history, index) => {
                    const { regionId, schoolId } = routeParams;
                    const pathSchoolCoach = regionId && schoolId ? GLUtil.pathStringify(PathConfig.SchoolTrainer, { regionId, schoolId, trainerId: history.coachId }) : null;
                    return pathSchoolCoach && GLGlobal.isActionValid(GSAdminAction.SchoolTrainerHome) ?
                        <ColumnLink className={'navigation-link'} url={pathSchoolCoach}>
                            {text}
                        </ColumnLink>
                        : text
                }
            },
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherVisitationHistoryReviewDateColumn}),
                dataIndex: SchoolTeacherReviewHistoryPropsModel.reviewDate,
                width: '15*',
                render: (text, history, index) => {
                    return history.reviewDate ? DateHelper.formatDate2Local(history.reviewDate) : '';
                }
            },
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherVisitationHistoryTypeColumn}),
                dataIndex: SchoolTeacherReviewHistoryPropsModel.typeText,
                width: '15*',
                render: (text, history, index) => {
                    switch (history.type) {
                        case VisitationType.OnSite:
                            return <Icon type="environment" />;
                        case VisitationType.LVA:
                            return <Icon type="video-camera" />;
                        default:
                            return null;
                    }
                },
            },
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherVisitationHistorySchoolColumn}),
                dataIndex: SchoolTeacherReviewHistoryPropsModel.schoolName,
                width: '30*',
                render: (text, history, index) => {
                    const {regionId, schoolId} = routeParams;
                    const pathCampues = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Schools, { regionId, schoolId }) : null;
                    return (GLGlobal.isActionValid(GSSchoolAction.Campuses) && pathCampues) ?
                        <ColumnLink className={'navigation-link'} url={pathCampues}>
                            {text}
                        </ColumnLink>
                        : text;
                },
            },
            {
                title: '',
                width: '10*',
                render: (text, history, index) => {
                    return (
                        <a className="navigation-link" onClick={(e) => {e.preventDefault(); onViewHistoryDetail(history);}}>
                            {fmtMsg({id:SchoolLocale.SchoolVisitationHistoryViewDetailsText})}
                        </a>
                    )
                },
            }
        ];
    }

    return (
        <div className='school-teacher-visitation-history'>
            <HistoryTable
                columns = {getColumns()}
                histories = {histories}
            />
        </div>
    )
}
